import FuseSidePanel from '@fuse/core/FuseSidePanel';
import { memo } from 'react';

function LeftSideLayout3() {
	return (
		<>
			<FuseSidePanel />
		</>
	);
}

export default memo(LeftSideLayout3);

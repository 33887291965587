import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import routes from 'hooks/api';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.handleAuthentication();
	}

	handleAuthentication = () => {
		const access_token = this.getAccessToken();
		const refresh_token = this.getRefreshToken();

		if (!access_token && !refresh_token) {
			this.emit('onNoAccessToken');

			return;
		}

		this.emit('onAutoLogin', true);
	};

	signInWithPhoneNumberAndPassword = (phoneNumber, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post(routes.signIn, {
					phoneNumber: String(phoneNumber)?.trim(),
					password
				})
				.then(response => {
					console.log(response, 1);
					if (response.data) {
						this.setSession(`Bearer ${response?.data?.access_token}`);
						this.setRefreshToken(`Bearer ${response?.data?.refresh_token}`);
						window.location.reload();
					} else {
						reject(response.data.error);
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(routes.accessToken, {
					headers: {
						common: {
							Authorization: this.getAccessToken()
						}
					}
				})
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(() => {
					axios
						.get(routes.refreshToken, {
							headers: {
								common: {
									Authorization: this.getRefreshToken()
								}
							}
						})
						.then(response => {
							if (response.data) {
								this.setSession(`Bearer ${response.data?.access_token}`);
								this.setRefreshToken(`Bearer ${response.data?.refresh_token}`);
								this.signInWithToken()
									.then(res => resolve(res))
									.catch(() => {
										this.logout();
										reject(new Error('Failed to get user data.'));
									});
								// resolve(response.data);
							} else {
								this.logout();
								reject(new Error('Failed to login with token.'));
							}
						})
						.catch(() => {
							this.logout();
							reject(new Error('Failed to login with token.'));
						});
				});
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	setRefreshToken = refresh_token => {
		if (refresh_token) {
			localStorage.setItem('jwt_refresh_token', refresh_token);
		} else {
			localStorage.removeItem('jwt_refresh_token');
		}
	};

	logout = () => {
		this.setSession(null);
		this.setRefreshToken(null);
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};

	getRefreshToken = () => {
		return window.localStorage.getItem('jwt_refresh_token');
	};
}

const instance = new JwtService();

export default instance;

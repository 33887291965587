import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.warehouseMan,
	routes: [
		{
			path: '/apps/warehouses-products',
			component: lazy(() => import('./list/Datas'))
		}
	]
};

export default DataAppConfig;

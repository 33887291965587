import { createSlice } from '@reduxjs/toolkit';

const generalSettings = createSlice({
	name: 'general-settings',
	initialState: {
		data: []
	},
	reducers: {
		setGeneralSettings: (state, action) => {
			state.data = action.payload;
		}
	}
});

export const { setGeneralSettings } = generalSettings.actions;
export default generalSettings.reducer;

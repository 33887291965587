import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataModeratorsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/users/partner-moderator',
			component: lazy(() => import('./list/Datas'))
		}
	]
};

export default DataModeratorsAppConfig;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const SettingsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/settings/currency',
			component: lazy(() => import('./list/Currency'))
		}
	]
};

export default SettingsAppConfig;

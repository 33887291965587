import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ImportAppConfig = {
	settings: {
		layout: {}
	},
	authRoles: [...authRoles.admin, ...authRoles.productModerator],
	routes: [
		{
			path: '/apps/imports',
			exact: true,
			component: lazy(() => import('./list/Imports'))
		}
	]
};

export default ImportAppConfig;

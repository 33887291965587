import { lazy } from 'react';
import { authRoles } from 'app/auth';

const PartnersAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.admin, ...authRoles.partnerModerator],
	routes: [
		{
			path: '/apps/partners',
			component: lazy(() => import('./list/Partners'))
		},
		{
			path: '/apps/partners-balance/:partnerId',
			exact: true,
			component: lazy(() => import('./partnerBalanceHistory/list/PartnerBalanceHistory'))
		}
	]
};

export default PartnersAppConfig;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataModeratorsAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.admin, ...authRoles.salesAgent],
	routes: [
		{
			path: '/apps/users/cashier/:dataID',
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/users/cashier',
			component: lazy(() => import('./list/Datas'))
		}
	]
};

export default DataModeratorsAppConfig;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/products/search',
			component: lazy(() => import('./search/Datas'))
		},
		{
			path: '/apps/products/create-edit',
			component: lazy(() => import('./update/Data'))
		},
		{
			path: '/apps/products',
			component: lazy(() => import('./list/Datas'))
		},
	]
};

export default DataAppConfig;

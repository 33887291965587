import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ExpenditureAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.cashier, ...authRoles.admin],
	routes: [
		{
			path: '/apps/expenditures',
			component: lazy(() => import('./list/Expenditure'))
		}
	]
};

export default ExpenditureAppConfig;

import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

export const ToastContext = React.createContext();

export default function ToastProvider(props) {
	const [open, setOpen] = React.useState(false);
	const [messages, setMessage] = React.useState('');
	const [types, setType] = React.useState('info');

	/**
	 * Init function.
	 *
	 * @param {string} message - Content text
	 * @param {string} [type] - An optional toast type
	 * @return {void}
	 */
	const showToast = (message, type = 'success') => {
		setOpen(true);
		setType(type);
		setMessage(message);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setMessage('');
		setOpen(false);
	};

	return (
		<ToastContext.Provider maxSnack={3} value={{ showToast }}>
			{props.children}
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert variant="filled" onClose={handleClose} severity={types} className="w-[var(--width)]">
					<Typography variant="h5">
						<strong>{messages}</strong>
					</Typography>
				</Alert>
			</Snackbar>
		</ToastContext.Provider>
	);
}

export const useToast = () => {
	return React.useContext(ToastContext);
};

import { useDeepCompareEffect } from '@fuse/hooks';
import FuseLayouts from '@fuse/layouts/FuseLayouts';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import { generateSettings, setSettings } from 'app/store/fuse/settingsSlice';
import { memo, useContext, useMemo, useCallback, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Translation, useTranslation } from 'react-i18next';
import socket from '../../../service/socket';
import { updateNavigationItem } from '../../../app/store/fuse/navigationSlice';
import { authRoles } from '../../../app/auth';
import { useToast } from '../../../hooks/useToast';
import audio from '../../../app/assest/ring.mp3';
import { getBadgesTotalCount } from '../../../utils/Navigation';

const useStyles = makeStyles(theme => ({
	'@global': {
		'code:not([class*="language-"])': {
			color: theme.palette.secondary.dark,
			backgroundColor: theme.palette.type === 'light' ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)',
			padding: '2px 3px',
			borderRadius: 2,
			lineHeight: 1.7
		},
		'table.simple tbody tr td': {
			borderColor: theme.palette.divider
		},
		'table.simple thead tr th': {
			borderColor: theme.palette.divider
		},
		'a:not([role=button])': {
			color: theme.palette.secondary.main,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		},
		'a.link, a:not([role=button])[target=_blank]': {
			background: fade(theme.palette.secondary.main, 0.2),
			color: 'inherit',
			borderBottom: `1px solid ${theme.palette.divider}`,
			textDecoration: 'none',
			'&:hover': {
				background: fade(theme.palette.secondary.main, 0.3),
				textDecoration: 'none'
			}
		},
		'[class^="border-"]': {
			borderColor: theme.palette.divider
		},
		'[class*="border-"]': {
			borderColor: theme.palette.divider
		},
		hr: {
			borderColor: theme.palette.divider
		},
		'::-webkit-scrollbar-thumb': {
			boxShadow: `inset 0 0 0 20px ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
				}`
		},
		'::-webkit-scrollbar-thumb:active': {
			boxShadow: `inset 0 0 0 20px ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
				}`
		},
		html: {
			backgroundColor: `${theme.palette.background.default}!important`,
			color: `${theme.palette.text.primary}!important`
		}
	},
	root: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary
	}
}));

function FuseLayout(props) {
	const dispatch = useDispatch();
	const { showToast } = useToast();
	const audioPlay = new Audio(audio);
	const { t } = useTranslation();
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const defaultSettings = useSelector(({ fuse }) => fuse.settings.defaults);
	const user = useSelector(({ auth }) => auth.user);

	// const NAVIGATION = useSelector((state) => state.fuse.navigation.entities.applications.children);

	// connect users to necessary sockets
	useEffect(() => {
		if (user?.role === 'DILLER' || user?.role === 'DILLER_HELPER') {
			const dillerId = user?.role === 'DILLER' ? user?.id : user?.dilerId;
			const totalCounts = [];
			const types = ['pending-order-count', 'pending-return-count', 'pending-transfer-count']

			socket.emit('join-diller-room', { dillerId });

			socket.on('pending-order-count', count => {
				totalCounts.push({
					type: 'pending-order-count',
					count
				});

				const totalCount = getBadgesTotalCount(totalCounts, types);

				if (count > 0) {
					audioPlay.play();
					showToast(t('Новый возврат!'));
				}

				dispatch(
					updateNavigationItem('diller', {
						id: 'diller',
						title: t('Diller'),
						type: 'collapse',
						icon: 'people_outline',
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						badge: {
							title: totalCount > 0 ? totalCount : null,
							bg: totalCount > 0 ? '#f3c407' : 'transparent',
							fg: totalCount > 0 ? '#ffffff' : 'transparent'
						},
						children: [
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'diller-products',
								title: t('Diller Products'),
								type: 'item',
								url: '/apps/diller-products'
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'self-orders',
								title: t('Self orders'),
								type: 'item',
								url: '/apps/diller/orders',
								exact: true
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'diller-transfers',
								title: t('Diller Transfers'),
								type: 'item',
								url: '/apps/diller/transfers',
								exact: true
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'sa-orders',
								title: t('Sales agent orders'),
								type: 'item',
								url: '/apps/diller/sa-orders',
								exact: true,
								badge: {
									title: +count > 0 ? count : null,
									bg: +count > 0 ? '#f3c407' : 'transparent',
									fg: +count > 0 ? '#ffffff' : 'transparent'
								}
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'return-goods',
								title: t('Return products'),
								type: 'item',
								url: '/apps/diller/return-goods',
								exact: true
							}
						]
					})
				);
			});

			socket.on('pending-return-count', count => {
				totalCounts.push({
					type: 'pending-return-count',
					count
				});

				const totalCount = getBadgesTotalCount(totalCounts, types);

				dispatch(
					updateNavigationItem('diller', {
						id: 'diller',
						title: t('Diller'),
						type: 'collapse',
						icon: 'people_outline',
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						badge: {
							title: totalCount > 0 ? totalCount : null,
							bg: totalCount > 0 ? '#f3c407' : 'transparent',
							fg: totalCount > 0 ? '#ffffff' : 'transparent'
						},
						children: [
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'diller-products',
								title: t('Diller Products'),
								type: 'item',
								url: '/apps/diller-products'
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'self-orders',
								title: t('Self orders'),
								type: 'item',
								url: '/apps/diller/orders',
								exact: true
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'diller-transfers',
								title: t('Diller Transfers'),
								type: 'item',
								url: '/apps/diller/transfers',
								exact: true,
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'sa-orders',
								title: t('Sales agent orders'),
								type: 'item',
								url: '/apps/diller/sa-orders',
								exact: true
							},
							{
								auth: [...authRoles.diller, ...authRoles.dillerHelper],
								id: 'return-goods',
								title: t('Return products'),
								type: 'item',
								url: '/apps/diller/return-goods',
								exact: true,
								badge: {
									title: +count > 0 ? count : null,
									bg: +count > 0 ? '#f3c407' : 'transparent',
									fg: +count > 0 ? '#ffffff' : 'transparent'
								}
							}
						]
					})
				);
			});
		} else if (user.role === 'WAREHOUSE_MAN') {
			const totalCounts = [];
			const types = ['pending-request-count', 'pending-return-count']

			socket.emit('join-warehouseman', { userId: user?.id });

			socket.on('pending-request-count', count => {
				totalCounts.push({
					type: 'pending-request-count',
					count
				});

				if (count > 0) {
					audioPlay.play();
					showToast(t('Новый заказ!'));
				}

				const totalCount = getBadgesTotalCount(totalCounts, types);

				dispatch(
					updateNavigationItem('warehouses', {
						id: 'warehouses',
						title: t('Warehouses'),
						type: 'collapse',
						icon: 'view_compact',
						auth: [...authRoles.admin, authRoles.warehouseMan, ...authRoles.productModerator],
						badge: {
							title: totalCount > 0 ? totalCount : null,
							bg: totalCount > 0 ? '#f3c407' : 'transparent',
							fg: totalCount > 0 ? '#ffffff' : 'transparent'
						},
						children: [
							{
								auth: [...authRoles.admin, ...authRoles.productModerator],
								id: 'all-warehouses',
								title: t('All Warehouses'),
								type: 'item',
								url: '/apps/warehouses',
								exact: true
							},
							{
								auth: authRoles.warehouseMan,
								id: 'add-warehouses-products',
								title: t('Warhouse Products'),
								type: 'item',
								url: '/apps/warehouses-products',
								exact: true
							},
							{
								auth: authRoles.warehouseMan,
								id: 'add-warehouses-order-requests',
								title: t('Warhouse Order Requests'),
								type: 'item',
								url: '/apps/warehouses-order-requests',
								exact: true,
								badge: {
									title: +count > 0 ? count : null,
									bg: +count > 0 ? '#f3c407' : 'transparent',
									fg: +count > 0 ? '#ffffff' : 'transparent'
								}
							},
							{
								auth: authRoles.warehouseMan,
								id: 'warehouses-returned-products',
								title: t('Returned Products'),
								type: 'item',
								url: '/apps/warehouse-returned-products',
								exact: true
							}
						]
					})
				);
			});

			socket.on('pending-return-count', count => {
				totalCounts.push({
					type: 'pending-return-count',
					count
				});

				if (count > 0) {
					audioPlay.play();
					showToast(t('Новый возврат!'));
				}

				const totalCount = getBadgesTotalCount(totalCounts, types);

				dispatch(
					updateNavigationItem('warehouses', {
						id: 'warehouses',
						title: t('Warehouses'),
						type: 'collapse',
						icon: 'view_compact',
						auth: [...authRoles.admin, authRoles.warehouseMan, ...authRoles.productModerator],
						badge: {
							title: totalCount > 0 ? totalCount : null,
							bg: totalCount > 0 ? '#f3c407' : 'transparent',
							fg: totalCount > 0 ? '#ffffff' : 'transparent'
						},
						children: [
							{
								auth: [...authRoles.admin, ...authRoles.productModerator],
								id: 'all-warehouses',
								title: t('All Warehouses'),
								type: 'item',
								url: '/apps/warehouses',
								exact: true
							},
							{
								auth: authRoles.warehouseMan,
								id: 'add-warehouses-products',
								title: t('Warhouse Products'),
								type: 'item',
								url: '/apps/warehouses-products',
								exact: true
							},
							{
								auth: authRoles.warehouseMan,
								id: 'add-warehouses-order-requests',
								title: t('Warhouse Order Requests'),
								type: 'item',
								url: '/apps/warehouses-order-requests',
								exact: true
							},
							{
								auth: authRoles.warehouseMan,
								id: 'warehouses-returned-products',
								title: t('Returned Products'),
								type: 'item',
								url: '/apps/warehouse-returned-products',
								exact: true,
								badge: {
									title: +count > 0 ? count : null,
									bg: +count > 0 ? '#f3c407' : 'transparent',
									fg: +count > 0 ? '#ffffff' : 'transparent'
								}
							}
						]
					})
				);
			});
		}
	}, [user?.dilerId, user.role, dispatch, user?.id]);

	const appContext = useContext(AppContext);
	const { routes } = appContext;
	const classes = useStyles(props);
	const location = useLocation();
	const { pathname } = location;
	const matched = matchRoutes(routes, pathname)[0];
	const newSettings = useRef(null);

	const shouldAwaitRender = useCallback(() => {
		let _newSettings;
		/**
		 * On Path changed
		 */
		// if (prevPathname !== pathname) {
		if (matched && matched.route.settings) {
			/**
			 * if matched route has settings
			 */

			const routeSettings = matched.route.settings;

			_newSettings = generateSettings(defaultSettings, routeSettings);
		} else if (!_.isEqual(newSettings.current, defaultSettings)) {
			/**
			 * Reset to default settings on the new path
			 */
			_newSettings = _.merge({}, defaultSettings);
		} else {
			_newSettings = newSettings.current;
		}

		if (!_.isEqual(newSettings.current, _newSettings)) {
			newSettings.current = _newSettings;
		}
	}, [defaultSettings, matched]);

	shouldAwaitRender();

	useDeepCompareEffect(() => {
		if (!_.isEqual(newSettings.current, settings)) {
			dispatch(setSettings(newSettings.current));
		}
	}, [dispatch, newSettings.current, settings]);

	// console.warn('::FuseLayout:: rendered');

	const Layout = useMemo(() => FuseLayouts[settings.layout.style], [settings.layout.style]);

	return _.isEqual(newSettings.current, settings) ? <Layout classes={{ root: classes.root }} {...props} /> : null;
}

export default memo(FuseLayout);

import AnalyticsDashboardAppConfig from './dashboards/analytics/AnalyticsDashboardAppConfig';
import WarehouseAppConfig from './warehouse/WarehouseAppConfig';
import ProductsAppConfig from './products/ProductsAppConfig';
import OrderProductsAppConfig from './order-product/OrderProductsAppConfig';
import DillerProductsAppConfig from './diller-products/DillerProductsAppConfig';
import UsersAppConfig from './users/UsersAppConfig';
import SettingsAppConfig from './settings/SettingsAppConfig';
import ExpendituresAppConfig from './expenditures/ExpendituresAppConfig';
import ImportsAppConfig from './imports/ImportsAppConfig';
import DillerAppConfig from './diller/DillerAppConfig';
import SalesAgentAppConfig from './sales-agent/SalesAgentAppConfig';
import PartnersAppConfig from './partners/PartnersAppConfig';
import discountsAppConfig from './discount/discountsAppConfig';
import ReturnProductsAppConfig from './diller/returned-products/ReturnedProductsAppConfig';
import ReportAppConfig from './report/ReportAppConfig';
import ServiceCenterAppConfig from './service-center/ServiceCenterAppConfig';

const appsConfigs = [
	AnalyticsDashboardAppConfig,
	ProductsAppConfig,
	OrderProductsAppConfig,
	DillerProductsAppConfig,
	PartnersAppConfig,
	ReportAppConfig,
	...SalesAgentAppConfig,
	...ServiceCenterAppConfig,
	...ReturnProductsAppConfig,
	...WarehouseAppConfig,
	...UsersAppConfig,
	...ExpendituresAppConfig,
	...SettingsAppConfig,
	...DillerAppConfig,
	...ImportsAppConfig,
	...discountsAppConfig
];

export default appsConfigs;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.serviceMan,
	routes: [
		{
			path: '/apps/service-center-products',
			component: lazy(() => import('./single/Data'))
		}
	]
};

export default DataAppConfig;

/**
 * Authorization Roles
 */
const authRoles = {
	admin: ['ADMIN'],
	productModerator: ['PRODUCT_MODERATOR'],
	partnerModerator: ['PARTNER_MODERATOR'],
	diller: ['DILLER'],
	dillerHelper: ['DILLER_HELPER'],
	salesAgent: ['SALES_AGENT'],
	warehouseMan: ['WAREHOUSE_MAN'],
	serviceMan: ['SERVICE_MAN'],
	cashier: ['CASHIER'],
	client: ['CLIENT'],
	serviceManHelper: ['SERVICE_MAN_HELPER'],
	user: [
		'ADMIN',
		'PRODUCT_MODERATOR',
		'PARTNER_MODERATOR',
		'DILLER',
		'DILLER_HELPER',
		'SALES_AGENT',
		'WAREHOUSE_MAN',
		'SERVICE_MAN',
		'CASHIER',
		'CLIENT',
		'SERVICE_MAN_HELPER'
	],
	onlyGuest: []
};

export default authRoles;

import { lazy } from 'react';
import { authRoles } from '../../../../auth';

const ExpenditureCategoriesAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.cashier, ...authRoles.admin],
	routes: [
		{
			path: '/apps/expenditures/expenditure-categories',
			component: lazy(() => import('./list/ExpenditureCategories'))
		}
	]
};

export default ExpenditureCategoriesAppConfig;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.admin, ...authRoles.productModerator],
	routes: [
		{
			path: '/apps/warehouses/:dataID',
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/warehouses',
			component: lazy(() => import('./list/Datas'))
		},
		
	]
};

export default DataAppConfig;

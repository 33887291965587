import { lazy } from 'react';
import { authRoles } from 'app/auth';

const TransfersAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.diller, ...authRoles.dillerHelper],
	routes: [
		{
			path: '/apps/diller/transfers',
			exact: true,
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/diller/transfers/transfer/:groupId',
			exact: true,
			component: lazy(() => import('./single/tabs/diller-transfers-tab/transferGroup/index'))
		}
	]
};

export default TransfersAppConfig;

import CategoryAppConfig from './category/DataAppConfig';
import CurrencyAppConfig from './currency/CurrencyAppConfig';
import GeneralSettingsAppConfig from './general/DataAppConfig';
import AdminReportGettersConfig from './AdminReportGetters/AdminReportGettersAppConfig';
import IncomeTypeAppConfig from './incomeType/IncomeTypeAppConfig';

const SettingsAppConfig = [
	GeneralSettingsAppConfig,
	CurrencyAppConfig,
	CategoryAppConfig,
	IncomeTypeAppConfig,
	AdminReportGettersConfig
];

export default SettingsAppConfig;

import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';

export const submitLogin =
	({ phoneNumber, password }) =>
	async dispatch => {
		dispatch(setLoading(true));
		return jwtService
			.signInWithPhoneNumberAndPassword(phoneNumber, password)
			.then(user => {
				dispatch(setUserData(user));

				return dispatch(loginSuccess());
			})
			.catch(errors => {
				return dispatch(loginError(errors));
			})
			.finally(() => {
				return dispatch(setLoading(false));
			});
	};

const initialState = {
	loading: false,
	success: false,
	errors: []
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
			state.errors = [];
		},
		loginError: (state, action) => {
			state.success = false;
			state.errors = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
	extraReducers: {}
});

export const { loginSuccess, loginError, setLoading } = loginSlice.actions;

export default loginSlice.reducer;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.warehouseMan,
	routes: [
		{
			path: '/apps/warehouses-order-requests/:dataID',
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/warehouses-order-requests',
			component: lazy(() => import('./list/Datas'))
		}
	]
};

export default DataAppConfig;

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const OrderAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.diller, ...authRoles.dillerHelper],
	routes: [
		{
			path: '/apps/diller/return-goods',
			exact: true,
			component: lazy(() => import('./single/Data'))
		}
	]
};

export default OrderAppConfig;

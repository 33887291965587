import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	authRoles: [...authRoles.admin, ...authRoles.productModerator],
	routes: [
		{
			path: '/apps/imports/:dataID',
			component: lazy(() => import('./single/Data'))
		}
	]
};

export default DataAppConfig;

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';
import routes from 'hooks/api';
import axios from 'axios';
import { setGeneralSettings } from '../../store/generals/index';

function UserMenu(props) {
	const route = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('jwt_access_token');
	const dispatch = useDispatch();

	useEffect(() => {
		axios({
			method: 'get',
			url: `${route}/general-setting?take=10&page=1`,
			headers: {
				Authorization: token
			}
		})
			.then(res => res?.data?.data && dispatch(setGeneralSettings(res?.data?.data)))
			.catch(err => alert(err.message));
	}, [route, dispatch, token]);

	const user = useSelector(({ auth }) => auth.user);

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<div className="hidden md:flex flex-col mx-4 items-end">
					<Typography component="span" className="font-semibold flex">
						{user?.fullName}
					</Typography>
					<Typography className="text-11 font-medium capitalize" color="textSecondary">
						{user?.role?.toString()}
						{(!user?.role || (Array.isArray(user?.role) && user?.role?.length === 0)) && 'Guest'}
					</Typography>
				</div>
				<Avatar
					className="md:mx-4"
					alt="user photo"
					src={
						user?.image && user?.image !== ''
							? `${routes.publicImages}/${user?.image}`
							: 'assets/images/avatars/default.png'
					}
				/>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{!user?.role || user?.role?.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/pages/profile" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="My Profile" />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const AllChemas = ({type=undefined})=>{

    
    const {t}=useTranslation()
    const loginSchema=yup.object().shape({
        phoneNumber: yup
            .string()
            .required('Please enter your phone number')
            .min(13, t("Login is too short - should be 13 chars minimum."))
            .max(13, t('Login is too long - should be 13 chars maximum.')),
        password: yup.string()
            .required('Please enter your password.')
            .min(4, 'Password is too short - should be 4 chars minimum.')
    });

    const passwordChangeSchema=type==="password"?yup.object().shape({
        newPassword:  yup.string()
        .required('Please enter your password.')
        .min(4, 'Password is too short - should be 4 chars minimum.'),
            confirmNewPassword: yup.string()
            .required('Please enter your password.')
            .min(4, 'Password is too short - should be 4 chars minimum.')
    }):yup.object()?.shape({});
    return {loginSchema, passwordChangeSchema}
}
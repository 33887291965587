import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DillersAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.admin, ...authRoles.productModerator],
	routes: [
		{
			path: '/apps/users/diller',
			exact: true,
			component: lazy(() => import('./list/Datas'))
		},
		{
			path: '/apps/users/diller/transfer-info-detailed/:groupId',
			exact: true,
			component: lazy(() => import('./list/diller-transfers-tab/transferGroup'))
		},
		{
			path: '/apps/users/diller/:id',
			exact: true,
			component: lazy(() => import('./single'))
		},
		{
			path: '/apps/users/diller/:id/diller-transfers/:transferId',
			exact: true,
			component: lazy(() => import('./diller-transfers/single/tabs/diller-transfers-tab/transferGroup/index'))
		},
		{
			path: '/apps/users/diller/:id/sa-orders/:orderId/:status',
			exact: true,
			component: lazy(() => import('./SA-order/order'))
		},
		{
			path: '/apps/users/diller/:id/sales-analytics/:agentId',
			exact: true,
			component: lazy(() => import('./analytics-diller/single/Datas'))
		},
		{
			path: '/apps/users/diller/:id/request/:requestId',
			exact: true,
			component: lazy(() => import('./request'))
		},
		{
			path: '/apps/users/diller/:id/orders/:orderId',
			exact: true,
			component: lazy(() => import('./order-products'))
		}
	]
};

export default DillersAppConfig;

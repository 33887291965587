import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.serviceMan, ...authRoles.serviceManHelper],
	routes: [
		{
			path: '/apps/service-center-orders',
			exact: true,
			component: lazy(() => import('.'))
		},
		{
			path: '/apps/service-center-orders/:id',
			exact: true,
			component: lazy(() => import('./products'))
		}
	]
};

export default DataAppConfig;

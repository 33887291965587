import { useCallback } from 'react';

export const getBadgesTotalCount = (counts, types) => {
	const lastCurrents = []

	types.forEach((currentType) => {
		const allCurrentTypeCounts = counts.filter(({ type }) => type === currentType)

		if (allCurrentTypeCounts) {
			lastCurrents.push(allCurrentTypeCounts[allCurrentTypeCounts.length - 1]?.count);
		}
	});

	return lastCurrents.reduce((a, b) => a + b, 0);
};
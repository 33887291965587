import { lazy } from 'react';
import { authRoles } from 'app/auth';

const AdminReportGettersConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/settings/admin-report-gertters',
			component: lazy(() => import('./list/AdminReportGetters'))
		}
	]
};

export default AdminReportGettersConfig;

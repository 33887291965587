import ProductModeratorAppConfig from './product-moderator/DataAppConfig';
import PartnerModeratorAppConfig from './partner-moderator/DataAppConfig';
import DillerAppConfig from './diller/DataAppConfig';
import SalesAgentAppConfig from './sales-agent/DataAppConfig';
import WarehouseManAppConfig from './warehouse-man/DataAppConfig';
import ServiceManAppConfig from './service-man/DataAppConfig';
import CashierAppConfig from './cashier/DataAppConfig';
import DillerHelperAppConfig from './diller-helper/DataAppConfig';
import ClientAppConfig from './client/DataAppConfig';
import ServiceManHelperAppConfig from './service-man-helper/DataAppConfig';

const AllUsersAppConfig = [
	ProductModeratorAppConfig,
	PartnerModeratorAppConfig,
	DillerAppConfig,
	DillerHelperAppConfig,
	SalesAgentAppConfig,
	WarehouseManAppConfig,
	ServiceManAppConfig,
	CashierAppConfig,
	ClientAppConfig,
	ServiceManHelperAppConfig
];

export default AllUsersAppConfig;

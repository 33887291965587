import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import 'react-tooltip/dist/react-tooltip.css';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import ToastProvider from 'hooks/useToast';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { ru } from 'date-fns/locale';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useEffect } from 'react';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';
import '../i18n';
// import  {ToastContainer}  from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName({ disableGlobal: true });
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: 1
		}
	}
});

const App = () => {
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<ToastProvider>
				{/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
				> */}
				<StylesProvider jss={jss} generateClassName={generateClassName}>
					<Provider store={store}>
						<QueryClientProvider client={queryClient}>
							<ReactQueryDevtools initialIsOpen={false} />
							<MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
								<Auth>
									<Router history={history}>
										<FuseAuthorization>
											<FuseTheme>
												<SnackbarProvider
													maxSnack={5}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'right'
													}}
													classes={{
														containerRoot:
															'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
													}}
												>
													<FuseLayout />
												</SnackbarProvider>
											</FuseTheme>
										</FuseAuthorization>
									</Router>
								</Auth>
							</MuiPickersUtilsProvider>
						</QueryClientProvider>
					</Provider>
				</StylesProvider>
				{/* </ToastContainer> */}
			</ToastProvider>
		</AppContext.Provider>
	);
};

export default App;
